import React, { Component } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"

class PageTemplate extends Component {
  render() {
    const page = this.props.data.wordpressPage

    const content = page.content.split('//www.nanini.be/wp-content').join('//content.nanini.be/wp-content')

    return (
      <Layout>
        <div className="page">
          <div className="row mt-3">
            <div className="col-12 col-text">
              <h2 className="title primary my-2">{page.title}</h2>
              <div dangerouslySetInnerHTML={{__html: content}}></div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default PageTemplate

export const query = graphql`
  query currentPageQuery($id: String!) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      content
      slug
      date(formatString: "MMMM DD, YYYY")
    }
  }
`
